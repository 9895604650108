
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class certification_audit extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  table1_all_data = []
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  table1_total = 0
  table1_search_data = {
    user_name: ''
  }

  rule_table1_search_data = {}

  table1_edit_dialog = {
    open: false,
    title: '审核'
  }

  table1_edit_data = {
    id: 0,
    user_name: '',
    work_unit: '',
    culture: 0,
    post: '',
    expertise: '',
    performance_res: '',
    accessory: '',
    status_: 0,
    opinion: ''
  }

  rule_table1_edit_data = {}

  culture_59 = new Map<any, string>([
    [1, '博士'],
    [2, '硕士'],
    [3, '本科'],
    [4, '大专'],
    [5, '大专以下']
  ])
  status_64 = new Map<any, string>([
    [0, '待审核'],
    [1, '通过'],
    [2, '不通过']
  ])
  status__65 = new Map<any, string>([
    [1, '通过'],
    [2, '不通过']
  ])

  table1_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.table1_search_data.user_name
    }
    Api.http_certification_audittable10(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_all_data = res.data.data
        this.table1_total = res.data.total
      }
    })
  }
  table1_edit_init(row) {
    Api.http_certification_audittable1get5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_edit_data.id = row.id
        this.table1_edit_data.user_name = res.data.user_name
        this.table1_edit_data.work_unit = res.data.work_unit
        this.table1_edit_data.culture = res.data.culture
        this.table1_edit_data.post = res.data.post
        this.table1_edit_data.expertise = res.data.expertise
        this.table1_edit_data.performance_res = res.data.performance_res
        this.table1_edit_data.accessory = res.data.accessory
        this.table1_edit_data.status_ = res.data.status_
        this.table1_edit_data.opinion = res.data.opinion
        this.table1_edit_dialog.open = true
      }
    })
  }
  table1_edit_cancle() {
    this.table1_edit_dialog.open = false
  }
  table1_edit_ok() {
    this.$refs.ref_table1_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.table1_edit_data.id,
          status_: this.table1_edit_data.status_,
          opinion: this.table1_edit_data.opinion
        }
        Api.http_certification_audittable15(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table1_edit_dialog.open = false
            this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  table1_init() {
    this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
  created() {
    this.table1_init()
  }
}
